export default function Custom404() {
  return (
    // <></>
    <div className="error-container">
      <div className="error-content">
        <div className="error-text">
          404 <br />
          <br />
          The page you are looking for was not found.
        </div>
      </div>
    </div>
  );
}
